import moment from 'moment';
import { ChangeEvent, useCallback } from 'react';

interface UseOnDateChangeHook {
  (outerOnChange: (date) => void): [(date: Date) => void, (event: ChangeEvent<HTMLInputElement>) => void];
}

export const useOnDateChange: UseOnDateChangeHook = (outerOnChange: (date) => void) => {
  const onChange = useCallback((date: Date) => {
    outerOnChange(date != null ? moment(date) : null);
  }, [outerOnChange]);

  const onChangeRaw = useCallback(({target}: { target: HTMLInputElement }) => {
    const date = moment(target.value, 'l');

    if (!date?.isValid()) {
      onChange(null);
    } else {
      onChange(date?.toDate());
    }
  }, [onChange]);

  return [onChange, onChangeRaw];
};
