import { useMemo } from 'react';
import moment from 'moment';

export const useLocaleValues = (localeString: string) => {
  const locale = useMemo(() => moment.localeData(localeString), [localeString]);

  const dateFormat = useMemo(() =>
      locale.longDateFormat('L')
        .replace(/MM/, 'M')
        .replace(/DD/, 'd')
        .replace(/D/, 'd')
        .replace(/Y/g, 'y'),
    [locale]
  );

  const placeholder = useMemo(() => locale.longDateFormat('L').toLowerCase(), [locale]);
  return [placeholder, dateFormat];
} ;
